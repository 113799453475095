import React, { useEffect, useState } from "react";
import { Link, useHistory, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Row,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import { getOrders, getClients } from "../../store/actions";
import Table from '../../components/Table';
import { getClients, postCode } from '../../helpers/backend_helper';

import { getLoggedInUserType } from '../../helpers/commonFunctions';
import JwtService from '../../helpers/jwtService';
import {
  ASSIGN_PERMISSION_SECONDARY_USER,
  GET_SECONDARY_USERS,
} from '../../helpers/url_helper';
import { getLogedinUser } from '../../helpers/commonFunctions';

const EcommerceOrders = (props) => {
  let authUser = getLogedinUser();
  let history = useHistory();
  const userType = getLoggedInUserType();
  const [orders, setOrders] = useState([]);

  const [orderList, setOrderList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [secondaryUserModel, setSecondaryModel] = useState();
  const [alert, setAlert] = useState({ variant: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [secondaryUserList, setSecondaryUserList] = useState([]);
  const [selectedUser, setSelectedSecondaryUser] = useState();
  const [clientAddModal, setClientAddModal] = useState(false);
  const [code, setCode] = useState('');

  // const pageOptions = {
  //   sizePerPage: 10,
  //   totalSize: 50, // replace later with size(orders),
  //   custom: true,
  // }
  const defaultSort = 'userFirstName';
  const defaultSortDirection = 'ASC';
  const [pageOption, setPageOption] = useState({
    page: 1,
    limit: 10,
    sortBy: 'userFirstName',
    sortDirection: 'ASC',
    search: '',
  });
  // const pageData = {
  //   "page": 1,
  //   "limit": 10,
  //   "sortBy":"firstName",
  //   "sortDirection":"ASC",
  //   "search":""
  // }

  function clientModalToggle() {
    setClientAddModal(!clientAddModal);
    // setSecondaryModel();
  }

  const getClientList = () => {
    setLoading(true);
    getClients(pageOption)
      .then((res) => {
        if (res.codes === 200) {
          setLoading(false);
          setOrders(res.data.items);
          setTotal(parseInt(res.data.total));
        } else {
          setOrders([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getClientList();
  }, [pageOption]);

  useEffect(() => {
    JwtService.get(
      GET_SECONDARY_USERS,
      {},
      {
        'journey-type': 'SecondaryUser',
      }
    ).then((res) => {
      if (res?.codes === 200) {
        let options = res?.data.map((item, index) => {
          return {
            ...item,
            value: item.id,
            label: `${item?.firstName} ${item?.lastName}`,
          };
        });
        if (options.length > 0) {
          setSelectedSecondaryUser(options[0]);
          setSecondaryUserList(options);
        } else {
          setSecondaryUserList([]);
        }
      }
    });
  }, []);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setOrderList(
      orders.filter((order) =>
        Object.keys(order).some(
          (key) =>
            typeof order[key] === 'string' &&
            order[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  // open modal
  function tog_standard() {
    setmodal_standard(!modal_standard);
    // setSecondaryModel();
  }

  const handleTableChangeT = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setPageOption((prevState) => ({
      ...prevState,
      page: page,
      limit: sizePerPage,
      sortBy: sortField !== null ? sortField : defaultSort,
      sortDirection: sortOrder !== undefined ? sortOrder : defaultSortDirection,
      search: searchText,
    }));
  };
  const handleRedirect = (row, data) => {
    history.push('/dashboard-overview', {
      client: row,
      clientName: `${data?.userFirstName} ${data?.userLastName}`,
    });
  };
  const columns = [
    {
      dataField: 'userId',
      text: 'Reference Id',
      sort: true,
      formatter: (row, data) => {
        return (
          <Link
            onClick={() => handleRedirect(row, data)}
            className="text-dark fw-bold"
          >
            {`${data?.code}`}
          </Link>
        );
      },
    },
    {
      dataField: 'userFirstName',
      text: 'First Name',
      sort: true,
    },
    {
      dataField: 'userLastName',
      text: 'Last Name',
      sort: false,
    },
    {
      dataField: 'isActive',
      text: 'Status',
      sort: false,
      formatter: (row) => {
        return (
          <Badge
            className={`badge bg-pill font-size-12 bg-soft-${
              row ? 'success' : 'danger'
            }`}
            color={row ? 'success' : 'danger'}
          >
            {row ? 'Active' : 'Suspended'}
          </Badge>
        );
      },
    },
    {
      text: 'Action',
      sort: false,
      formatter: (row, data) => {
        return (
          <Button
            color="primary"
            size="small"
            onClick={() => {
              setSecondaryModel(data);
              setmodal_standard(true);
            }}
          >
            <i className="uil uil-link"></i>
          </Button>
        );
      },
    },
  ];

  const activateSecondaryUser = () => {
    let values = {
      userId: secondaryUserModel.userId,
      // "businessId": secondaryUserModel.businessUserId,
      permissionLevel: 1,
      secondaryUserId: selectedUser.id,
    };

    JwtService.post(ASSIGN_PERMISSION_SECONDARY_USER, values)
      .then((res) => {
        if (res.codes === 200) {
          setAlert({ variant: 'success', message: res.message });
          setShowAlert(true);

          setTimeout(() => {
            setmodal_standard(false);
            getClientList();
          }, 1000);
        } else {
          setAlert({ variant: 'danger', message: res.message });
          setShowAlert(true);
        }
      })
      .catch((error) => {});
  };
  const handleChange = (e) => {
    setCode(e.target.value);
  };
  const handleClick = async () => {
    if (code) {
      await postCode({ referralCode: code }).then((res) => {
        if (res?.codes === 200) {
          clientModalToggle();
          setCode('');
          toast.success('client added successfully');
          getClientList();
        } else {
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(authUser?.deepLink);
    toast.success('Copy Link Successfully');
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative">
            <Breadcrumbs title="" breadcrumbItem="Client List" />
            {userType !== undefined && userType === 'P' && (
              <Button
                variant="primary"
                color="primary"
                className="position-absolute add-secondary-user"
                onClick={() => {
                  setClientAddModal(true);
                }}
              >
                <i className="uil uil-plus"></i> Add Client
              </Button>
            )}
          </div>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Table
                    keyField="id"
                    data={orderList}
                    sizePerPage={pageOption.limit}
                    page={pageOption.page}
                    columns={columns}
                    onTableChange={handleTableChangeT}
                    noDataIndication={'No Data Found'}
                    loading={loading}
                    isPagination={true}
                    isSearch={true}
                    totalSize={total}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
          >
            <div className="modal-header">
              <h6 className="modal-title mt-0" id="myModalLabel">
                Delegate permission to user ?
              </h6>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <Alert
                    color={alert.variant}
                    isOpen={showAlert}
                    toggle={() => setShowAlert(false)}
                    fade={false}
                  >
                    {alert.message}
                  </Alert>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Select
                    classNamePrefix="select"
                    defaultValue={secondaryUserList[0]}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={secondaryUserList}
                    onChange={(value) => {
                      setSelectedSecondaryUser(value);
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => {
                  activateSecondaryUser();
                }}
              >
                Yes
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={clientAddModal}
            toggle={() => {
              clientModalToggle();
            }}
          >
            <AvForm className="form-horizontal">
              <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                  Add New Client
                </h6>
                <button
                  type="button"
                  onClick={() => {
                    setClientAddModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col>
                    <Alert
                      color={alert.variant}
                      isOpen={showAlert}
                      toggle={() => setShowAlert(false)}
                      fade={false}
                    >
                      {alert.message}
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <AvField
                        name="code"
                        label="Client Code"
                        type="text"
                        placeholder="Enter Client Code"
                        value={code}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <button
                  type="button"
                  onClick={() => {
                    handleCopy();
                  }}
                  className="btn btn-secondary waves-effect "
                  data-dismiss="modal"
                  style={{ float: 'right ' }}
                >
                  Share Link
                </button>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <button
                    type="button"
                    onClick={() => {
                      clientModalToggle();
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    // disabled={secondaryUserModel && secondaryUserModel.isActive}
                    onClick={handleClick}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </AvForm>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceOrders.propTypes = {};

// const mapStateToProps = (state) => {

//   return ({
//     orders: state.clients.clients,
//   })
// }

// const mapDispatchToProps = dispatch => ({
//   onGetOrders: () => dispatch(getClients()),
// })

export default withRouter(EcommerceOrders);
