import axios from "axios";
import React from 'react';
import ReactDOM from 'react-dom';
import SessionExpiredPopup from '../../components/Common/SessionExpiredPopup';

const BASE_API_URL = process.env.REACT_APP_EDOC_API_URL || '';

class JwtService {
  init() {
    this.setInterceptors();
    // axios.defaults.headers.common['responseCode'] = 'starfolioapi';
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            this.showSessionExpiredPopup();
          }
          throw err;
        });
      }
    );
  };

  showSessionExpiredPopup = () => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    ReactDOM.render(
      <SessionExpiredPopup open={true} onClose={() => {}} />,
      div
    );
  };

  post = (url, data, journeyType) => {
    let authUser = localStorage.getItem('authUser');
    let headers = {};

    if (authUser) {
      let token = JSON.parse(authUser).data.accessToken;
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${BASE_API_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'app-name': process.env.REACT_APP_NAME,
        'app-version': process.env.REACT_APP_VERSION,
        ...journeyType,
        ...headers,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 401) {
          this.showSessionExpiredPopup();
          return Promise.reject('Unauthorized - Session expired');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  get = (url, params, journeyType) => {
    let authUser = localStorage.getItem('authUser');
    let headers = {};

    if (authUser) {
      let token = JSON.parse(authUser).data.accessToken;
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${BASE_API_URL}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'app-name': process.env.REACT_APP_NAME,
        'app-version': process.env.REACT_APP_VERSION,
        ...journeyType,
        ...headers,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          this.showSessionExpiredPopup();
          return Promise.reject('Unauthorized - Session expired');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  put = (url, data, journeyType) => {
    const authUser = localStorage.getItem('authUser');
    const headers = {};

    if (authUser) {
      const token = JSON.parse(authUser).data.accessToken;
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${BASE_API_URL}${url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'app-name': process.env.REACT_APP_NAME,
        'app-version': process.env.REACT_APP_VERSION,
        ...journeyType,
        ...headers,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 401) {
          this.showSessionExpiredPopup();
          return Promise.reject('Unauthorized - Session expired');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((err) => {
        console.error('Error:', err);
      });
  };
}

const instance = new JwtService();

export default instance;