import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import UploadIcon from '../../assets/svg/upload.svg'; // Adjust this path as necessary
import { toast } from 'react-toastify';

// ========== Styled Components ==========
const DropBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px dashed #F0F0F0',
  borderRadius: theme.shape.borderRadius,
  height: '120px',
  textAlign: 'center',
  cursor: 'pointer',
  backgroundColor: '#FCFCFC',
  color: '#9E9E9E',
  marginBottom: '0px',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: '#F5F5F5',
  },
  overflow: 'hidden',
  position: 'relative',
}));

const FormGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  borderRight: '1px solid rgba(33, 33, 32, 0.1)',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0),
    borderRight: '1px solid transparent',
  },
}));

const UploadFileGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0),
  },
}));

// ========== Reusable FileUpload Component ==========
const FileUpload = ({ label, onFileUploaded, authToken, field }) => {
  const MAX_SIZE = 10 * 1024 * 1024; // 10MB
  const [file, setFile] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleFile = async (selectedFile) => {
    if (selectedFile.size > MAX_SIZE) {
      toast.error('File size exceeds 10MB.');
      return;
    }

    setUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('field', field);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_EDOC_API_URL}/api/v1/businessPartner/uploadKYCDocument`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`);
      }

      const data = await response.json();
      // The API returns the uploaded file URL in `data.data`
      const url = data.data;

      setUploadedUrl(url);
      setFile(selectedFile);
      onFileUploaded(url);
    } catch (err) {
      console.error(err);
      setError('Failed to upload file. Please try again.');
      toast.error('Failed to upload file. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleFile(selectedFile);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      handleFile(droppedFile);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = () => {
    setFile(null);
    setUploadedUrl('');
    onFileUploaded('');
  };

  const renderFilePreview = () => {
    if (!file) return null;

    const isImage = file.type.startsWith('image/');
    const isPdf = file.type === 'application/pdf';
    const isWord =
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type === 'application/msword';

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        width="100%"
        height="100%"
        position="relative"
      >
        {isImage ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            {isPdf ? (
              <PictureAsPdfIcon color="error" />
            ) : isWord ? (
              <DescriptionIcon color="primary" />
            ) : (
              <DescriptionIcon color="action" />
            )}
            <Typography variant="Body_medium_16" noWrap>
              {file.name}
            </Typography>
          </Stack>
        )}
        <IconButton
          onClick={handleRemoveFile}
          size="small"
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 1)',
            },
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  return (
    <Stack gap={1}>
      <Typography variant="Body_medium_16">{label}</Typography>
      <DropBoxStyled
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        component="label"
      >
        {!file && !uploading ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={UploadIcon} alt="Upload" height={28} />
            <Typography variant="Body_medium_16">
              Drag and Drop files here, or{' '}
              <Typography variant="Body_medium_16" color="orange.main">
                Browse
              </Typography>
            </Typography>
            <input
              type="file"
              hidden
              onChange={handleFileChange}
              accept=".pdf, .doc, .docx, .jpeg, .jpg, .png"
            />
          </Box>
        ) : uploading ? (
          <CircularProgress />
        ) : (
          renderFilePreview()
        )}
      </DropBoxStyled>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          color: 'rgba(33, 33, 32, 0.4)',
        }}
      >
        <Typography variant="Body_light_12">
          Supports: PDF, Word, JPEG, PNG
        </Typography>
        <Typography variant="Body_light_12">Maximum size: 10MB</Typography>
      </Stack>
      {error && (
        <Typography variant="Body_light_12" color="error">
          {error}
        </Typography>
      )}
    </Stack>
  );
};

// ========== Main KycIndividual Component ==========
const WelcomePage = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const reviewStatus = localStorage.getItem('reviewStatus');
  const authToken = JSON.parse(localStorage.getItem('authUser'));

  // State for ninNumber
  const [ninNumber, setNinNumber] = useState('');
  // State for the uploaded file URL
  const [identityDocumentUrl, setIdentityDocumentUrl] = useState('');

  const handleSubmit = () => {
    // Validate form fields and file uploads
    if (!ninNumber || !identityDocumentUrl) {
      toast.error(
        'Please enter your NIN number and upload your identity document.'
      );
      return;
    }

    // Prepare data to send to the updateKYC endpoint
    const data = {
      ninNumber,
      identityDocumentUrl,
    };

    fetch(
      `${process.env.REACT_APP_EDOC_API_URL}/api/v1/businessPartner/updateKYC`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken?.data?.accessToken}`,
          'journey-type': 'kyc-individual',
          'app-name': process.env.REACT_APP_NAME,
          'app-version': process.env.REACT_APP_VERSION,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`KYC update failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        // Optionally, set a localStorage flag or do any additional handling
        localStorage.setItem('reviewStatus', 'KYC_PENDING');
        toast.success('KYC submitted successfully!');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to submit KYC. Please try again.');
      });
  };

  const handleCancel = () => {
    // Reset all fields
    setNinNumber('');
    setIdentityDocumentUrl('');
  };

  return (
    <Box className="page-content">
      <Container>
        <Grid container>
          {/* Welcome Card */}
          <Grid item xs={12}>
            <Card sx={{ p: 3, borderRadius: '12px' }}>
              <Stack gap={1}>
                <Box mb={1.5}>
                  <Typography variant="Body_medium_18">
                    👋 Hello{' '}
                    <Typography variant="Body_medium_18" color="orange.main">
                      {userData?.name}!
                    </Typography>
                  </Typography>
                </Box>

                <Typography variant="Header_semibold_32">
                  Welcome to E-DOC Online
                </Typography>

                <Typography
                  variant="Body_light_14"
                  fontWeight={400}
                  sx={{
                    color: 'rgba(33, 33, 32, 0.6)',
                  }}
                >
                  We will assist you to connect to millions of financial
                  accounts through our direct banking connection, recurring bank
                  payments with a single, secure Open Banking API and verifying
                  right candidates for your needs.
                </Typography>
              </Stack>
            </Card>
          </Grid>

          {/* KYC Form Card (shown only if reviewStatus is 'PENDING', adjust if needed) */}
          {reviewStatus === 'PENDING' && (
            <Grid item xs={12} my={3}>
              <Card sx={{ p: 3, borderRadius: '12px' }}>
                <Grid container spacing={3}>
                  {/* Form Title */}
                  <Grid item xs={12}>
                    <Typography variant="Body_semibold_20">
                      Please complete your KYC
                    </Typography>
                  </Grid>

                  {/* Divider */}
                  <Grid item xs={12} mt={1} mb={3}>
                    <Divider />
                  </Grid>

                  {/* Left Form Fields */}
                  <FormGrid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      {/* NIN Number Field */}
                      <Grid item xs={12}>
                        <Stack gap={1}>
                          <Typography variant="Body_medium_16">
                            NIN Number
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            placeholder="Enter your NIN Number"
                            value={ninNumber}
                            onChange={(e) => setNinNumber(e.target.value)}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </FormGrid>

                  {/* Right File Upload */}
                  <UploadFileGrid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      {/* Identity Document Upload */}
                      <Grid item xs={12}>
                        <FileUpload
                          label="Upload ID Card or International Passport"
                          onFileUploaded={setIdentityDocumentUrl}
                          authToken={authToken?.data?.accessToken}
                          field="identityDocument"
                        />
                      </Grid>
                    </Grid>
                  </UploadFileGrid>

                  {/* Divider */}
                  <Grid item xs={12} my={1}>
                    <Divider />
                  </Grid>

                  {/* Action Buttons */}
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      gap={1}
                    >
                      <Button
                        variant="contained"
                        onClick={handleCancel}
                        sx={{
                          backgroundColor: 'common.white',
                          color: 'common.black',
                          border: '1px solid rgba(33, 33, 32, 0.2)',
                          ':hover': {
                            backgroundColor: '#f4f4f4',
                            color: 'common.black',
                            border: '1px solid #f4f4f4',
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" onClick={handleSubmit}>
                        Confirm
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default WelcomePage;
