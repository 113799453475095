import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Button, Row, Col } from 'reactstrap';
import JwtService from '../../helpers/jwtService';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as RightIcon } from '../../assets/images/small-check.svg';

const SelectPlan = () => {
  const { token } = useParams();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loadingStates, setLoadingStates] = useState({
    Silver: false,
    Gold: false,
    Platinum: false,
  });

  useEffect(() => {
    document.body.className = 'authentication-bg';
    return () => {
      document.body.className = '';
    };
  }, []);

  const buyPlan = async (plan) => {
    try {
      const res = await JwtService.put(
        `/api/v1/businessPartner/activateBusinessPartnerUsers/${token}`,
        { plan },
        {
          'journey-type': 'Register',
        }
      );
      if (res.codes === 200) {
        toast.success(res.message);
        localStorage.removeItem('plan');
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      } else if (res.codes === 400) {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('An error occurred');
    }
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const groupInfo = {
    Silver: {
      categorizedData: ['Overview(inflow,Outflow only)'],
      pricing: '₦ 250',
      business: [
        { title: '<2000 Units', amount: '₦ 250' },
        { title: '>2000 Units', amount: '₦ 200' },
      ],
    },
    Gold: {
      categorizedData: ['Overview', 'Statements'],
      pricing: '₦ 1000',
      business: [
        { title: '<2000 Units', amount: '₦ 500' },
        { title: '>2000 Units', amount: '₦ 450' },
      ],
    },
    Platinum: {
      categorizedData: ['Overview', 'Risk', 'Category insights', 'Statements'],
      pricing: '₦ 1500',
      business: [
        { title: '<2000 Units', amount: '₦ 750' },
        { title: '>2000 Units', amount: '₦ 500' },
        { title: 'Monthly Subscription', amount: '100 K' },
      ],
    },
  };

  return (
    <div className="account-pages my-2 pt-sm-5">
      <div className="text-center mt-2">
        <h2 className="mb-2" style={styles.header}>
          Choose Plan
        </h2>
      </div>
      <div className="mt-2 text-center">
        <p className="text-muted mb-4">
          Simple Pricing. No Hidden Fees. Advance Features for your Business.
        </p>
      </div>
      <Row className="justify-content-center">
        {Object.entries(groupInfo).map(([planName, planDetails]) => (
          <Col key={planName} xs={12} sm={6} md={4} lg={3}>
            <div style={styles.fullHeight}>
              <Card
                style={{
                  ...styles.card,
                  maxWidth: '360px',
                  border:
                    selectedPlan === planName
                      ? '2px solid orange'
                      : '1px solid #ccc',
                }}
                onClick={() => handleSelectPlan(planName)}
              >
                <CardBody style={styles.cardBody}>
                  <div style={{ flex: 1, maxWidth: '600px', margin: '0 10px' }}>
                    <div className="text-start mt-4">
                      <h3 className="text-primary">{planName}</h3>
                    </div>

                    <Section title="Categorized Data">
                      {planDetails.categorizedData.map((data, index) => (
                        <p key={index} className="text-muted mb-0">
                          <RightIcon /> {data}
                        </p>
                      ))}
                    </Section>
                    <Section title="Pricing">
                      <p className="text-muted mb-0">
                        Customers
                        <span style={styles.rightAlign}>
                          {planDetails.pricing}
                        </span>
                      </p>
                    </Section>

                    <div className="text-center">
                      <Section title="Business:">
                        {planDetails.business.map((businessDetail, index) => (
                          <p key={index} className="text-muted mb-0">
                            {businessDetail.title}
                            <span style={styles.rightAlign}>
                              {businessDetail.amount}
                            </span>
                          </p>
                        ))}
                      </Section>
                    </div>
                  </div>
                </CardBody>
                <div className="text-center mt-4 mb-4 p-2">
                  <Button
                    style={styles.button}
                    color="primary"
                    className="w-100 waves-effect waves-light mt-2"
                    onClick={() => handleBuyPlan(planName)}
                    disabled={loadingStates[planName]}
                  >
                    {loadingStates[planName] ? 'Processing...' : 'Buy Plan'}
                  </Button>
                </div>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );

  function handleBuyPlan(planName) {
    if (loadingStates[planName]) return;
    setLoadingStates({ ...loadingStates, [planName]: true });

    // Map plan names to payload names
    const payloadName = {
      Silver: 'Silver_Basic',
      Gold: 'Gold_Basic',
      Platinum: 'Platinum_Basic',
    }[planName];

    buyPlan(payloadName).finally(() => {
      setLoadingStates({ ...loadingStates, [planName]: false });
    });
  }
};

const Section = ({ title, content, children }) => (
  <>
    <div className="text-start mt-2">
      <h4 style={styles.sectionTitle}>{title}</h4>
    </div>
    <div className="mt-2 text-start">
      {content ? (
        <p className="text-muted mb-4">
          Pay per pull
          <span style={styles.rightAlign}>{content}</span>
        </p>
      ) : (
        children
      )}
    </div>
  </>
);

const styles = {
  header: { fontWeight: 600, fontSize: 32, color: '#001823' },
  fullHeight: { height: '100%' },
  card: {
    height: '100%',
    borderRadius: 16,
    cursor: 'pointer',
    margin: '20px',
    marginBottom: '20px', // Add margin bottom to create space between cards
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Ensure space between card content and button
    '@media (maxwidth: 768px)': {
      marginTop: '40px', // Adjust the spacing for smaller screens
    },
  },
  cardBody: {
    flex: 1, // Ensure card body takes up the available space
  },
  rightAlign: { float: 'right', fontWeight: 'bold', color: 'black' },
  button: { borderRadius: 10 },
  sectionTitle: { fontWeight: 600, fontSize: 16, color: '#001823' },
};

export default SelectPlan;
