import EditIcon from "@mui/icons-material/Edit";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import IconSettings from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  banksList,
  getBusinessProfile,
  updateBusinessProfile,
  getAccountName,
} from "../../helpers/backend_helper";

import Products from "./Products";

const LinkGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRight: "1px solid rgba(33, 33, 32, 0.1)",
  [theme.breakpoints.down("md")]: {
    borderRight: "1px solid transparent",
    borderBottom: "1px solid rgba(33, 33, 32, 0.1)",
  },
}));

const ListWrapBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "260px",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Settings = () => {
  const [selectedMenu, setSelectedMenu] = useState("Business Profile");
  const [businessName, setBusinessName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [settlementAccountNumber, setSettlementAccountNumber] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [editMode, setEditMode] = useState({
    email: false,
    phone: false,
    settlementAccountNumber: false,
  });
  const [accountName, setAccountName] = useState(""); // State to store the account name
  const theme = useTheme();
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await banksList();
        if (Array.isArray(response.data)) {
          setBankList(response.data);
        } else {
          console.error("Fetched banks data is not an array:", response);
        }
      } catch (error) {
        console.error("Failed to fetch banks:", error);
      }
    };

    fetchBanks();
  }, []);

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      try {
        const response = await getBusinessProfile();
        const data = response.data;
        if (data) {
          const businessPartner = data.businessPartner;
          setBusinessName(businessPartner.companyName);

          const planMapping = {
            Silver_Basic: "Silver",
            Gold_Basic: "Gold",
            Platinum_Basic: "Platinum",
          };

          setSubscriptionPlan(
            planMapping[businessPartner.plan] || businessPartner.plan
          );
          setEmail(data.businessPartner.buisnessContactEmail || "");
          setPhone(data.businessPartner.buisnessPhoneNumber || "");
          if (selectedMenu === "Settlement Account") {
            setSettlementAccountNumber(
              data.businessPartner.settlementAccountNumber || ""
            );
            const settlementBankId = Number(
              data.businessPartner.settlementBankId
            );
            const selectedBank = bankList.find(
              (bank) => bank.id === settlementBankId
            );
            setSelectedBank(selectedBank?.id || "");
          }
        }
      } catch (error) {
        console.error(`Error fetching ${selectedMenu} data:`, error);
      }
    };

    fetchBusinessProfile();
  }, [selectedMenu, bankList]);

  const listItemStyles = (menuName) => ({
    backgroundColor: selectedMenu === menuName ? "#ffe6d5" : "transparent",
    borderRadius: theme.shape.borderRadius,
    mb: 1,

    span: {
      color:
        selectedMenu === menuName
          ? `${theme.palette.orange.main} !important`
          : `${theme.palette.text.primary}`,
    },

    svg: {
      fill:
        selectedMenu === menuName
          ? `${theme.palette.orange.main} !important`
          : `${theme.palette.text.primary} !important`,
    },

    "&:hover": {
      span: {
        color: theme.palette.orange.main,
      },

      backgroundColor: "#ffe6d5",
      svg: {
        fill: `${theme.palette.orange.main} !important`,
      },
    },
  });

  const renderFormField = (label, value, setValue, fieldKey, options = {}) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        mt: 3,
      }}
    >
      <InputLabel
        sx={{ mb: 1, fontWeight: 500, fontSize: "medium", color: "black" }}
      >
        {label}
      </InputLabel>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{ backgroundColor: theme.palette.background.paper }}
        disabled={!editMode[fieldKey]}
        error={options.error || false}
        helperText={options.helperText || ""}
        placeholder={options.placeholder || ""}
        InputProps={{
          endAdornment: !options.disabled && (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    [fieldKey]: !prev[fieldKey],
                  }))
                }
                sx={{ color: theme.palette.primary.secondary }}
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  const handleSaveProfile = () => {
    if (!email.includes("@")) {
      toast.error("Please enter a valid email address.");
      return;
    }
    if (!/^\d+$/.test(phone)) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    const requestData = {
      buisnessContactEmail: email,
      buisnessPhoneNumber: phone,
    };

    updateBusinessProfile(requestData)
      .then((response) => {
        toast.success("Profile updated successfully.");
        setEditMode({ email: false, phone: false });
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("Failed to update profile. Please try again.");
      });
  };

  // Add a new state for the confirmation dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Function to handle dialog open and close
  const handleDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  // Updated handleSaveSettlementAccount to close the dialog after confirmation
  const handleSaveSettlementAccount = () => {
    const requestData = {
      settlementAccountNumber: settlementAccountNumber,
      settlementBankId: selectedBank,
      settlementBankName: bankList.find((bank) => bank.id === selectedBank)
        ?.displayName,
    };

    updateBusinessProfile(requestData)
      .then((response) => {
        toast.success("Settlement Account updated successfully.");
        setEditMode({ settlementAccountNumber: false });
        setOpenConfirmDialog(false); // Close the dialog
      })
      .catch((error) => {
        console.error("Error updating Settlement account:", error);
        toast.error("Failed to update Settlement account. Please try again.");
      });
  };

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchAccountName = async () => {
    if (!selectedBank || !settlementAccountNumber) {
      return;
    }

    try {
      const params = {
        bankName: bankList.find((bank) => bank.id === selectedBank)?.bankName, // Use the correct key "bankName"
        accountNumber: settlementAccountNumber, // Use the correct key "accountNumber"
      };
      const response = await getAccountName(params);
      if (response?.data?.accountName) {
        setAccountName(response.data.accountName);
      } else {
        setAccountName("");
      }
    } catch (error) {
      console.error("Error fetching account name:", error);
    }
  };


  const triggerFileInput = () => {
    document.getElementById("profilePicInput").click();
  };

  return (
    <Box className="page-content">
      <Container>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconSettings fontSize="medium" color="action" />
          <Typography variant="Body_light_20" fontWeight={600}>
            Settings
          </Typography>
        </Stack>

        <Card
          sx={{
            marginTop: 3,
          }}
        >
          <Grid container justifyContent={"space-between"}>
            <LinkGrid item xs={12} md={3}>
              <ListWrapBox>
                <List component="nav" sx={{ p: 0 }}>
                  <ListItemButton
                    onClick={() => setSelectedMenu("Business Profile")}
                    sx={listItemStyles("Business Profile")}
                  >
                    <ListItemIcon>
                      <PersonOutlineOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Business Profile" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => setSelectedMenu("Settlement Account")}
                    sx={listItemStyles("Settlement Account")}
                  >
                    <ListItemIcon>
                      <ReceiptLongOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settlement Account" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => setSelectedMenu("Products")}
                    sx={listItemStyles("Products")}
                  >
                    <ListItemIcon>
                      <Inventory2OutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                  </ListItemButton>
                </List>
              </ListWrapBox>
            </LinkGrid>
            <ContentGrid item xs={12} md={9}>
              {selectedMenu === "Business Profile" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ position: "relative", display: "inline-block" }}>
                    <Avatar
                      alt="User Profile Picture"
                      src={profilePic}
                      sx={{ width: 120, height: 120, mb: 3 }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        bottom: 30,
                        right: 5,
                        width: 24,
                        height: 24,
                        backgroundColor: "white",
                        border: "1px solid ",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                      onClick={triggerFileInput}
                    >
                      <EditIcon sx={{ width: 18 }} />
                    </IconButton>
                  </Box>
                  <input
                    accept="image/*"
                    type="file"
                    id="profilePicInput"
                    onChange={handleProfilePicChange}
                    style={{ display: "none" }}
                  />
                  {renderFormField(
                    "Business Name",
                    businessName,
                    setBusinessName,
                    "businessName",
                    {
                      disabled: true,
                    }
                  )}
                  {renderFormField(
                    "Subscription Plan",
                    subscriptionPlan,
                    setSubscriptionPlan,
                    "subscriptionPlan",
                    {
                      disabled: true,
                    }
                  )}
                  {renderFormField(
                    "Business Contact Email",
                    email,
                    setEmail,
                    "email",
                    {
                      placeholder: "Enter your business Contact email",
                    }
                  )}
                  {renderFormField(
                    "Business Phone Number",
                    phone,
                    setPhone,
                    "phone",
                    {
                      placeholder: "Enter your business phone number",
                    }
                  )}
                  <Box sx={{ mt: 4, width: "100%" }}>
                    <Button
                      disableElevation
                      fullWidth
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={handleSaveProfile}
                    >
                      Save Profile
                    </Button>
                  </Box>
                </Box>
              )}
              {selectedMenu === "Settlement Account" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  
                  {renderFormField(
                    "Settlement Account",
                    settlementAccountNumber,
                    (value) => {
                      setSettlementAccountNumber(value);
                    },
                    "settlementAccountNumber",
                    {
                      placeholder: "Enter your settlement account number",
                    }
                  )}
                  
                  <Box sx={{ mt: 4, width: "100%" }}>
                    <InputLabel
                      sx={{
                        mb: 1,
                        fontWeight: 500,
                        fontSize: "medium",
                        color: "black",
                      }}
                    >
                      Bank Name
                    </InputLabel>
                    <Select
                      value={selectedBank || ""}
                      onChange={(event) => {
                        handleBankChange(event);
                        fetchAccountName(); // Fetch account name when bank changes
              
                      }}
                      displayEmpty
                      fullWidth
                      renderValue={(selected) => {
                        if (selected === "") {
                          return (
                            <span
                              style={{ color: "#aaa", fontWeight: "light" }}
                            >
                              Select your bank
                            </span>
                          );
                        }
                        const selectedBankObj = bankList.find(
                          (bank) => bank.id === selected
                        );
                        return (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={selectedBankObj.icon}
                              alt={selectedBankObj.displayName}
                              style={{
                                width: 24,
                                height: 24,
                                marginRight: 8,
                              }}
                            />
                            {selectedBankObj.displayName}
                          </Box>
                        );
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select your bank
                      </MenuItem>
                      {bankList.map((bank) => (
                        <MenuItem key={bank.id} value={bank.id}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ListItemIcon>
                              <img
                                src={bank.icon}
                                alt={bank.displayName}
                                style={{ width: 24, height: 24 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={bank.displayName} />
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {accountName && ( // Only show when accountName is available
                    <Box sx={{ mt: 2, width: "100%" }}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Account Name: {accountName}
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{ mt: 4, width: "100%" }}>
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={handleDialogOpen}
                      disabled={!selectedBank || !settlementAccountNumber}
                    >
                      Add Account
                    </Button>
                  </Box>
                </Box>
              )}
              <Dialog
                open={openConfirmDialog}
                onClose={handleDialogClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
              >
                <DialogTitle id="confirm-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-dialog-description">
                    Are you sure you want to Update Your Settlement Account?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleDialogClose}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSaveSettlementAccount}
                    variant="contained"
                    color="secondary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              {selectedMenu === "Products" && <Products />}
            </ContentGrid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};

export default Settings;
