import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { Link, withRouter } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getBusinessProductEntitlement } from '../../helpers/backend_helper';
import { getLoggedInUserType } from '../../helpers/commonFunctions';
const SidebarContent = (props) => {
  const ref = useRef();
  const userType = getLoggedInUserType();
  const [approvedProduct, setApprovedProduct] = useState([]);
  const reviewStatus = localStorage.getItem('reviewStatus');
  const fetchProduct = async () => {
    await getBusinessProductEntitlement()
      .then((res) => {
        if (res.codes === 200) {
          const array = [];
          if (res?.data?.length) {
            res?.data.map((item) => {
              if (item.status === 'APPROVED') {
                array.push(item.productCode);
              }
            });
          }
          setApprovedProduct(array);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(() => {
        toast.error('Something went wrong!');
      });
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location?.pathname;

    new MetisMenu('#side-menu');
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: '100%' }}
        ref={ref}
        className="sidebar-menu-scroll"
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li
              style={{
                pointerEvents: approvedProduct.includes('affordability_full')
                  ? 'auto'
                  : 'none',
              }}
            >
              <Link to="/client-list" className="waves-effect">
                <i className="uil uil-user-nurse"></i>

                <span>Client List</span>
              </Link>
            </li>
            {userType !== undefined && userType === 'P' && (
              <li style={{ pointerEvents: 'auto' }}>
                <Link to="/admin-users" className="waves-effect">
                  <i className="uil uil-setting"></i>

                  <span>Admin Users</span>
                </Link>
              </li>
            )}
            <li style={{ pointerEvents: 'auto' }}>
              <Link to="/wallet" className="waves-effect">
                <i className="uil uil-wallet"></i>
                <span>Wallet</span>
              </Link>
            </li>
            <li
              style={{
                pointerEvents: approvedProduct.includes('directDebit')
                  ? 'auto'
                  : 'none',
              }}
            >
              <Link to="/direct-debit" className="waves-effect">
                <i className="uil uil-percentage"></i>
                <span>Direct Debit</span>
              </Link>
            </li>
            <li
              style={{
                pointerEvents: approvedProduct.includes('directDebit')
                  ? 'auto'
                  : 'none',
              }}
            >
              <Link to="/settlement" className="waves-effect">
                <i className="uil uil-newspaper"></i>
                <span>Settlements</span>
              </Link>
            </li>

            <li
              style={{
                pointerEvents: approvedProduct.includes('incomeVerification')
                  ? 'auto'
                  : 'none',
              }}
            >
              <Link to="/income-verification" className="waves-effect">
                <i className="uil uil-credit-card-search"></i>
                <span>Income Verification</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
