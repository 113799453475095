import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import {
  MainStyle,
  RootStyle,
  StyledDivider2,
} from "../../components/Common/Style";
import StyledCircularProgress from "../../components/Common/StyledCircularProgress";
import DashboardUINav from "../../components/Navbar/DashboardUINav/DashboardUINav";
import { getConsent, getDashboard } from '../../helpers/backend_helper';
import {
  CircularProgressWrapper,
  OverviewWrapper,
  PageBoxWrapper,
  RiskCardInfoWrapper,
  TableCellDataAmount,
  TableCellDataItemName,
  TableCellHead,
  TableWrapper,
  TopCard,
  TopCardInfoBox,
  TopCardListItem,
  TopCardListWrapper
} from "./DashboardUIStyle";
import MonthlyAffordability from "./Tabs/MonthlyAffordability";
import MonthlyExpenses from "./Tabs/MonthlyExpenses";
import MonthlyIncome from "./Tabs/MonthlyIncome";

const monthRange = [
  {
    title: "3 Months",
    value: 3,
  },
  {
    title: "6 Months",
    value: 6,
  },
  {
    title: "9 Months",
    value: 9,
  },
  {
    title: "12 Months",
    value: 12,
  },
];

const accountIds = [
  {
    title: "0235087159",
    value: null,
  },
  {
    title: "02350871589",
    value: null,
  },
];
const typeOptions = [
  {
    title: "Total",
    value: "tran_amount_total_for_period",
  },
  {
    title: "Monthly Average",
    value: "tran_amount_monthly_average",
  },
];

const DashboardOverview = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [selectAccount, setSelectAccount] = useState(null);
  const [selectMonth, setSelectMonth] = useState();
  const [filletElement, setFilletElement] = useState([]);
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState();
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [accountsNumberList, setAccountsNumberList] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [openIncome, setOpenIncome] = useState(false);
  const [selectedType, setSelectedType] = useState(
    'tran_amount_total_for_period'
  );
  const [monthlyLiquidity, setMonthlyLiquidity] = useState([]);
  const [grantInflowTotal, setGrantInflowTotal] = useState();
  const [grantOutFlowTotal, setGrantOutFlowTotal] = useState();
  const [totalExpense, setTotalExpense] = useState([]);
  const [totalIncome, setTotalIncome] = useState([]);
  const [totalAffordability, setTotalAffordability] = useState([]);
  const [totalOutFlow, setTotalOutFlow] = useState([]);
  const [riskIndicators, setRiskIndicators] = useState();
  const [dashboardData, setDashboardData] = useState();
  const [totalInFlow, setTotalInFlow] = useState([]);
  const [liquidity_ResidualIncome, setLiquidity_ResidualIncome] = useState(0);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedConsent, setSelectedConsent] = useState({});

  const [liquidity_Predicted_balance, setLiquidity_Predicted_balance] =
    useState(0);

  const handleClickOpenIncomeDialog = () => {
    setOpenIncome(true);
  };
  const handleCloseIncomeDialog = () => {
    setOpenIncome(false);
  };

  const [openExpenses, setOpenExpenses] = useState(false);
  const [consent, setConsent] = useState();
  const handleClickOpenExpensesDialog = () => {
    setOpenExpenses(true);
  };
  const handleCloseExpensesDialog = () => {
    setOpenExpenses(false);
  };

  const [openAffordability, setOpenAffordability] = useState(false);
  const handleClickOpenAffordabilityDialog = () => {
    setOpenAffordability(true);
  };
  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };
  const getDashboardData = async () => {
    let hasAccessStatement = true;
    let endDate = moment().format('YYYY-MM-DD');
    setLoading(true);
    await getConsent(location)
      .then((res) => {
        if (res.codes == 200) {
          if (res.data?.length) {
            let accountNumberList = [];
            res?.data.forEach((v) => {
              if (
                v.consent.data.products.filter(
                  (item) =>
                    item.code === 'affordability_basic' ||
                    item.code === 'affordability_full'
                ).length &&
                v.accountNumber &&
                v.dashboardAvailable
              ) {
                accountNumberList.push({
                  title: `XXXXXX${v.accountNumber.slice(-4)}`,
                  value: v.accountNumber,
                });
              }
            });
            if (!accountNumberList.length) {
              history.push('/statement', { ...location?.state });
              hasAccessStatement = false;
            }
            accountNumberList.push({
              title: "CONSOLIDATED",
              value: "all",
              icon: "",
              bankName: "",
            });
            setAccountsNumberList(accountNumberList);
  
            let selectedProduct = res?.data?.find(
              (item) => item.accountNumber === selectAccount
            );
  
            if (selectedProduct && selectAccount?.length) {
              endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
                'YYYY-MM-DD'
              );
              let dateRangArray = [
                {
                  startDate: selectedProduct?.consent?.data?.dateFrom,
                  endDate: selectedProduct?.consent?.data?.dateTo,
                },
              ];
              calculateDateRang(dateRangArray, setSelectedDateRang);
              setSelectedConsent(selectedProduct);
            } else {
              let dateRangArray = res?.data.map((v) => {
                return {
                  startDate: v.consent?.data?.dateFrom,
                  endDate: v.consent?.data?.dateTo,
                };
              });
              let allEndDate = res?.data.map((v) => v.consent?.data?.dateTo);
              let maxEndDate =
                allEndDate.length &&
                allEndDate.reduce(function (a, b) {
                  return a > b ? a : b;
                });
  
              calculateDateRang(dateRangArray, setSelectedDateRang);
              endDate = moment(maxEndDate).format('YYYY-MM-DD');
            }
            setEndDate(endDate);
            setConsent(res?.data);
          }
        } else if (res?.codes === 404) {
          history.push('/login');
        } else {
          setLoading(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong while fetching consent data!'
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Error fetching consent data: ${err.message}`);
      });
  };
  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === 'affordability_full'
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== 'all' && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      setSelectedAccountType(maxObject?.accountType);
    }
  }, [consent]);

  useEffect(() => {
    getDashboardData();
  }, [selectMonth, selectAccount, selectedEndDate]);
  useEffect(() => {
    let dataArray = [];
    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    setFilletElement(dataArray);
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    let payload = {
      userId: location?.state?.client,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1].value,
      account_id: selectAccount,
      accountType: selectedAccountType || selectedConsent?.accountType,
    };
    if (
      selectAccount &&
      payload?.userId &&
      payload?.date_to &&
      payload?.n_months
    ) {
      getDashboard(payload).then((res) => {
        if (res?.codes === 200) {
          setLoading(false);
          // if (res.data?.data?.data.length) {
          let getIncome = res.data?.data.filter((item) => {
            return (
              item.aggregation_lvl === 1 &&
              item.tran_type === 'I' &&
              item.category_lvl1 === 'Income'
            );
          });

          let getExpense = res?.data?.data.filter((item) => {
            return (
              item.aggregation_lvl === 1 &&
              item.tran_type === 'E' &&
              item.category_lvl1 === 'Expense'
            );
          });
          let getAffordability = res?.data?.data.filter((item) => {
            return (
              item.aggregation_lvl === 1 &&
              item.tran_type === null &&
              item.category_lvl1 === 'Affordability'
            );
          });
          let getInFlow = res?.data?.data.filter((item) => {
            return (item.aggregation_lvl =
              1 &&
              item.tran_type === 'I' &&
              item.category_lvl1 != 'Inflow Reversals' &&
              item.category_lvl2 &&
              item[selectedType] &&
              item[selectedType] !== 0);
          });
          let getOutFlow = res.data?.data.filter((item) => {
            return (item.aggregation_lvl =
              1 &&
              item.tran_type === 'E' &&
              item.category_lvl2 &&
              item[selectedType] &&
              item[selectedType] !== 0);
          });

          let getRiskIndicators = res?.data?.data.filter((item) => {
            return (
              item.is_risky === 1 &&
              item.category_lvl2 &&
              item.category_lvl1 != 'Inflow Reversals'
            );
          });
          let liquidity = res.data?.liquidity;
          const lq = liquidity.find((c) => c.recommendation === 'Low');
          setLiquidity_ResidualIncome(lq?.average_residual_income);
          setLiquidity_Predicted_balance(lq?.predicted_balance);
          setTotalIncome(getIncome);
          setTotalExpense(getExpense);
          setTotalAffordability(getAffordability);
          setTotalInFlow(getInFlow);
          setTotalOutFlow(getOutFlow);
          setRiskIndicators(getRiskIndicators);
          // }
          setMonthlyLiquidity(liquidity);
          setDashboardData(res?.data?.monthly);
          setLoading(false);
        } else if (res?.codes === 404) {
          history.push('/login');
        } else {
          setTotalIncome([]);
          setTotalExpense([]);
          setTotalAffordability([]);
          setTotalInFlow([]);
          setTotalOutFlow([]);
          setRiskIndicators([]);
          setDashboardData(res?.data?.monthly);
          setLoading(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      });
    }
  }, [selectedDateRang, endDate]);
  useEffect(() => {
    if (totalInFlow.length) {
      const result = totalInFlow.reduce(
        (total, thing) => total + thing[selectedType],
        0
      );
      setGrantInflowTotal(result);
    } else {
      setGrantInflowTotal(0);
    }
    if (totalOutFlow.length) {
      const result = totalOutFlow.reduce(
        (total, thing) => total + thing[selectedType],
        0
      );
      setGrantOutFlowTotal(result);
    } else {
      setGrantOutFlowTotal(0);
    }
  }, [selectedType, totalInFlow, totalOutFlow]);
  return (
    <RootStyle>
      <DashboardUINav
        location={location}
        dashboardData={dashboardData}
        consent={consent}
      />
      <MainStyle>
        {loading ? (
          <Loader />
        ) : (
          <OverviewWrapper>
            <PageBoxWrapper>
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: { lg: 5, xs: 15 } }}
              >
                {selectAccount !== 'all' && selectedConsent?.balance && (
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: { lg: 5, xs: 15 } }}
                  >
                    <Stack
                      direction="column"
                      flexWrap="wrap"
                      alignItems="left"
                      gap={1}
                      sx={{ flexGrow: 1 }}
                    >
                      <Box>
                        <Typography
                          variant="Body_semibold_32"
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          Balance : ₦{selectedConsent.balance}
                        </Typography>
                      </Box>
                      <Stack>
                        <Typography
                          sx={{ fontSize: '16px', textAlign: 'left' }}
                          variant="Body_semibold_32"
                          color="orange.main"
                        >
                          As at{' '}
                          {moment(new Date(selectedConsent.createdAt)).format(
                            'DD/MM/yyyy'
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                <Box sx={{ flexGrow: 2, mt: { sm: 0, xs: 5 } }}>
                  <Grid container gap={2} justifyContent="flex-end">
                    <Grid item md={2.5} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        Account Number
                      </Typography>
                      <Autocomplete
                        id="select-business-partner"
                        options={accountsNumberList}
                        getOptionLabel={(option) => option.title}
                        // PaperComponent={CustomPaper}
                        value={accountsNumberList?.find(
                          (item) => item.value === selectAccount
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ 'aria-label': 'Without label' }}
                            hiddenLabel
                            fullWidth
                            {...params}
                          />
                        )}
                        onChange={(e, value) => {
                          setSelectAccount(value?.value || null);
                        }}
                      />
                    </Grid>

                    <Grid item md={2.5} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        Type
                      </Typography>
                      <Autocomplete
                        id="select-business-partner"
                        options={typeOptions}
                        getOptionLabel={(option) => option.title}
                        // PaperComponent={CustomPaper}
                        value={typeOptions.find(
                          (item) => item.value === selectedType
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ 'aria-label': 'Without label' }}
                            hiddenLabel
                            fullWidth
                            {...params}
                          />
                        )}
                        onChange={(e, value) => {
                          setSelectedType(value.value);
                        }}
                      />
                    </Grid>

                    {/* <Grid item md={2.5} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        Month
                      </Typography>
                      <Autocomplete
                        id="select-business-partner"
                        options={selectedDateRang}
                        getOptionLabel={(option) =>
                          moment(option).format("MMMM - YYYY")
                        }
                        // PaperComponent={CustomPaper}
                        value={selectedDateRang.find(
                          (item) => item === selectedEndDate
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ "aria-label": "Without label" }}
                            hiddenLabel
                            fullWidth
                            {...params}
                          />
                        )}
                        onChange={(e, newDate) => {
                          setSelectedEndDate(
                            newDate ? moment(newDate).format("YYYY-MM-DD") : ""
                          );
                        }}
                      />
                    </Grid> */}

                    <Grid item md={2.5} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        Filter Period
                      </Typography>
                      <Autocomplete
                        id="select-business-partner"
                        options={[...filletElement, ...selectedDateRang]}
                        getOptionLabel={(option) => {
                          if (
                            moment(option?.title).format('MMMM - YYYY') !==
                            'Invalid date'
                          ) {
                            return moment(option?.title).format('MMMM - YYYY');
                          } else {
                            return option?.title;
                          }
                        }}
                        // PaperComponent={CustomPaper}
                        value={
                          selectMonth
                            ? [...filletElement, ...selectedDateRang].find(
                                (item) => {
                                  if (selectedEndDate) {
                                    return item.value === selectedEndDate;
                                  } else {
                                    return item.value === selectMonth;
                                  }
                                }
                              )
                            : filletElement[filletElement.length - 1]
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ 'aria-label': 'Without label' }}
                            hiddenLabel
                            fullWidth
                            {...params}
                          />
                        )}
                        onChange={(e, newData) => {
                          setSelectMonth(newData?.value);
                          setSelectedEndDate(newData?.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>

              <Grid container spacing={5} alignItems="center" mt={3}>
                <Grid item md={4} xs={12}>
                  <TopCard>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleClickOpenIncomeDialog}
                    >
                      <Typography variant="Body_medium_16">Income</Typography>
                      <ChevronRightIcon />
                    </Stack>
                    <TopCardInfoBox>
                      <Typography variant="Body_light_14">
                        {selectedType === 'tran_amount_monthly_average' ? 'Monthly Average' : 'Total'}
                      </Typography>

                      <Stack direction="row" alignItems="center" gap={3}>
                        <Typography variant="Body_semibold_32">
                          ₦{' '}
                          {(
                            (totalIncome?.length &&
                              totalIncome[0]?.[selectedType]) ||
                            0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                        <StyledCircularProgress
                          type="percentage"
                          progressValue={(totalIncome[0]?.confidence_level
                            ? totalIncome[0]?.confidence_level * 100
                            : 0
                          ).toFixed(1)}
                          indicatorColor={theme.palette.success.main}
                          selectedEndDate={selectedEndDate}
                          size="60"
                        />
                      </Stack>
                    </TopCardInfoBox>

                    {/* <TopCardInfoBox>
                      <Typography variant="Body_medium_14">
                        Income Stability
                      </Typography>
                      <Typography variant="Body_light_12">
                        Based on 2 sources: 2 recurring incomes
                      </Typography>
                    </TopCardInfoBox> */}

                    <Stack direction="row" width="100%" alignItems="flex-end">
                      <CircularProgressWrapper>
                        <Typography variant="Body_medium_14">
                          Amount Consistency
                        </Typography>

                        <StyledCircularProgress
                          progressValue={(
                            (totalIncome.length &&
                              parseFloat(
                                totalIncome[0]?.tran_amount_consistency * 100
                              )) ||
                            0
                          ).toFixed(2)}
                          indicatorColor={theme.palette.success.main}
                          selectedEndDate={selectedEndDate}
                        />
                      </CircularProgressWrapper>

                      <CircularProgressWrapper>
                        <Typography variant="Body_medium_14">
                          Regularity
                        </Typography>
                        <StyledCircularProgress
                          progressValue={(
                            (totalIncome.length &&
                              parseFloat(
                                totalIncome[0]?.tran_amount_regularity * 100
                              )) ||
                            0
                          ).toFixed(2)}
                          indicatorColor={theme.palette.success.main}
                          selectedEndDate={selectedEndDate}
                        />{' '}
                      </CircularProgressWrapper>

                      <CircularProgressWrapper>
                        <Typography variant="Body_medium_14">
                          Longevity
                        </Typography>

                        <StyledCircularProgress
                          type="noCirculation"
                          progressValue={
                            (totalIncome.length &&
                              parseFloat(
                                totalIncome[0]?.tran_amount_longevity * 100
                              )) ||
                            0
                          }
                          selectMonth={
                            selectMonth
                              ? filletElement.find(
                                  (item) => item.value === selectMonth
                                )?.value
                              : filletElement[filletElement.length - 1]?.value
                          }
                          indicatorColor={theme.palette.orange.main}
                          selectedEndDate={selectedEndDate}
                        />
                      </CircularProgressWrapper>
                    </Stack>
                  </TopCard>
                </Grid>

                <Grid item md={4} xs={12}>
                  <TopCard>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleClickOpenExpensesDialog}
                    >
                      <Typography variant="Body_medium_16">Expenses</Typography>
                      <ChevronRightIcon />
                    </Stack>{' '}
                    <TopCardInfoBox>
                      <Typography variant="Body_light_14">
                      {selectedType === 'tran_amount_monthly_average' ? 'Monthly Average' : 'Total'} Expenses{' '}
                      </Typography>
                      <Typography variant="Body_semibold_32">
                        ₦{' '}
                        {totalExpense.length &&
                        totalExpense[0]?.[selectedType] < 0 ? (
                          <>
                            {(
                              (totalExpense.length &&
                                totalExpense[0]?.[selectedType]) ||
                              0
                            )
                              .toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })
                              .replace('-', '')}
                          </>
                        ) : (
                          <>
                            {(
                              (totalExpense.length &&
                                totalExpense[0]?.[selectedType]) ||
                              0
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </>
                        )}
                      </Typography>
                    </TopCardInfoBox>
                    <TopCardListWrapper>
                      <TopCardListItem>
                        <Typography variant="Body_medium_14">
                          Expenses
                        </Typography>

                        <Stack direction="column" alignItems="end">
                          <Typography variant="Body_medium_14">
                            ₦{' '}
                            {totalExpense.length &&
                            totalExpense[0]?.[selectedType] < 0 ? (
                              <>
                                {(
                                  (totalExpense.length &&
                                    totalExpense[0]?.[selectedType]) ||
                                  0
                                )
                                  .toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })
                                  .replace('-', '')}
                              </>
                            ) : (
                              <>
                                {(
                                  (totalExpense.length &&
                                    totalExpense[0]?.[selectedType]) ||
                                  0
                                ).toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                })}
                              </>
                            )}
                          </Typography>
                          <Typography variant="Body_light_12">
                          {selectedType === 'tran_amount_monthly_average' ? 'Monthly Average' : 'Total'}
                          </Typography>
                        </Stack>
                      </TopCardListItem>

                      <StyledDivider2 />
                    </TopCardListWrapper>
                  </TopCard>
                </Grid>

                <Grid item md={4} xs={12}>
                  <TopCard>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleClickOpenAffordabilityDialog}
                    >
                      <Typography variant="Body_medium_16">
                        Affordability
                      </Typography>
                      <ChevronRightIcon />
                    </Stack>{' '}
                    <Grid container>
                      <TopCardInfoBox item>
                        <Typography variant="Body_light_14">
                          Average Residual Income
                        </Typography>
                        <Typography variant="Body_semibold_32">
                          ₦{' '}
                          {liquidity_ResidualIncome?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{' '}
                        </Typography>
                      </TopCardInfoBox>
                      <TopCardInfoBox item md={6} xs={12} pl={4}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography variant="Body_light_14">
                            Predicted Balance
                          </Typography>
                        </Box>
                        <Typography variant="Body_semibold_32">
                          ₦{' '}
                          {liquidity_Predicted_balance?.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                            }
                          )}{' '}
                        </Typography>
                      </TopCardInfoBox>
                    </Grid>
                    <TopCardListWrapper>
                      <TopCardListItem>
                        <Typography variant="Body_medium_14">
                          Loan Recommendation
                        </Typography>
                      </TopCardListItem>
                    </TopCardListWrapper>
                    <TopCardListWrapper>
                      {monthlyLiquidity.map((liquidity, index) => {
                        return (
                          <Box component={'span'} key={index}>
                            <TopCardListItem>
                              <Typography variant="Body_light_14">
                                {liquidity.months} months
                              </Typography>

                              <Stack direction="column" alignItems="end">
                                <Typography variant="Body_semibold_14">
                                  ₦{' '}
                                  {liquidity.liquidity_amount?.toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )}{' '}
                                </Typography>
                                <Typography variant="Body_light_12"></Typography>
                              </Stack>
                            </TopCardListItem>

                            <StyledDivider2 />
                          </Box>
                        );
                      })}
                    </TopCardListWrapper>
                  </TopCard>
                </Grid>
              </Grid>

              <Grid container spacing={5} alignItems="center" mt={5}>
                <Grid item md={7} xs={12}>
                  <TopCard>
                    <Box>
                      <Grid container spacing={1} alignItems="flex-start">
                        <Grid item md={6} xs={12}>
                          <TableWrapper>
                            <Table
                              stickyHeader
                              sx={{
                                minWidth: 350,
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCellHead>Inflow</TableCellHead>
                                  <TableCellHead />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {totalInFlow?.map((row, index) => (
                                  <TableRow hover key={index}>
                                    <TableCellDataItemName>
                                      {row.category_lvl2}
                                    </TableCellDataItemName>
                                    <TableCellDataAmount>
                                      ₦{' '}
                                      {(
                                        row?.[selectedType] || 0
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                      })}
                                    </TableCellDataAmount>
                                  </TableRow>
                                ))}
                                <TableRow hover>
                                  <TableCellDataItemName>
                                    Total
                                  </TableCellDataItemName>
                                  <TableCellDataItemName>
                                    ₦{' '}
                                    {(grantInflowTotal || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </TableCellDataItemName>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableWrapper>{' '}
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TableWrapper>
                            <Table
                              stickyHeader
                              sx={{
                                minWidth: 350,
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCellHead>Outflow</TableCellHead>
                                  <TableCellHead />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {totalOutFlow?.map((row, index) => (
                                  <TableRow key={row.category_lvl2}>
                                    <TableCellDataItemName>
                                      {row.category_lvl2}
                                    </TableCellDataItemName>
                                    <TableCellDataAmount>
                                      ₦{' '}
                                      {(
                                        row?.[selectedType] || 0
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                      })}
                                    </TableCellDataAmount>
                                  </TableRow>
                                ))}
                                <TableRow hover>
                                  <TableCellDataItemName>
                                    Total
                                  </TableCellDataItemName>
                                  <TableCellDataItemName>
                                    ₦{' '}
                                    {(grantOutFlowTotal || 0).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </TableCellDataItemName>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableWrapper>{' '}
                        </Grid>
                      </Grid>
                    </Box>
                  </TopCard>
                </Grid>

                <Grid item md={5} xs={12}>
                  <TopCard sx={{ overflow: 'auto' }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="Body_medium_16">
                        Risk Indicators
                      </Typography>
                      {/* <ChevronRightIcon /> */}
                    </Stack>

                    <RiskCardInfoWrapper>
                      <Grid container spacing={2}>
                        {riskIndicators?.map((row, index) => (
                          <>
                            {row?.[selectedType] && (
                              <Grid
                                container
                                key={index}
                                md={6}
                                xs={12}
                                item
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  padding: theme.spacing(2),
                                  borderBottom: '2px solid #002E45',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="flex-start"
                                  gap={1}
                                >
                                  <Box>
                                    <CircleIcon
                                      sx={{
                                        fill: theme.palette.success.main,
                                        width: '12px',
                                        height: '12px',
                                      }}
                                    />
                                  </Box>

                                  <Stack direction="column">
                                    <Typography variant="Body_semibold_12">
                                      {row.category_lvl2}
                                    </Typography>
                                    <Typography variant="Body_light_10">
                                      ₦{' '}
                                      {(
                                        row?.[selectedType] || 0
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                      })}{' '}
                                    </Typography>
                                    {row?.comments?.length ? (
                                      <>
                                        {row?.comments.map((item, index) => (
                                          <Box key={index} component="li">
                                            <Typography variant="Body_semibold_12">
                                              {item}
                                            </Typography>
                                          </Box>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Stack>
                                </Stack>
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid>
                    </RiskCardInfoWrapper>
                  </TopCard>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  opacity: '0.5',
                }}
              >
                Data Fetch from{' '}
                {moment(selectedEndDate ? selectedEndDate : endDate)
                  .subtract(
                    selectedEndDate
                      ? 1
                      : selectMonth ||
                          filletElement[filletElement.length - 1]?.value,
                    'Month'
                  )
                  .format('DD/MM/YYYY')}{' '}
                to{' '}
                {moment(selectedEndDate ? selectedEndDate : endDate).format(
                  'DD/MM/YYYY'
                )}
              </Box>
            </PageBoxWrapper>
          </OverviewWrapper>
        )}
      </MainStyle>

      <MonthlyIncome
        open={openIncome}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseIncomeDialog}
      />

      <MonthlyExpenses
        open={openExpenses}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseExpensesDialog}
      />

      <MonthlyAffordability
        open={openAffordability}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseAffordabilityDialog}
      />
    </RootStyle>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

      let diff = moment(endDate).diff(moment(lastDate), 'days');
      if (diff < 0) {
        lastDate = moment(endDate).format('YYYY-MM-DD');
      }

      result.push(lastDate);
      startDate.add(1, 'month');
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();
        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });
    setSelectedDateRang(uniqueDates);
  });
}

export default DashboardOverview;
