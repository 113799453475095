import React, { useEffect, useState } from 'react';

//Import Images
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import JwtService from '../../helpers/jwtService';

import RSuccessIcon from '../../assets/svg/rsuccess.svg';
import { BoxWrapper, FormCard } from './common/AuthStyle';
import FormLogo from './common/FormLogo';

const VerifyUser = (props) => {
  const { token } = useParams();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [visible, setAlert] = useState(false);

  useEffect(() => {
    document.body.className = 'authentication-bg';
    return function cleanup() {
      document.body.className = '';
    };
  });
  useEffect(() => {
    const checkPlanSelectionRequired = async () => {
      try {
        const response = await JwtService.get(
          `/api/v1/businessPartner/activateBusinessPartnerUsers/${token}/verify`,
          {},
          { 'journey-type': 'Register' }
        );

        if (response.data.isValid && response.data.planSelectionRequired) {
          setTimeout(() => {
            window.location.href = `/choose-plan/${token}`;
          }, 2000);
        } else {
          buyPlan();
        }
      } catch (error) {
        toast.error('An error occurred while verifying the activation.');
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      }
    };

    checkPlanSelectionRequired();
  }, []);

  const buyPlan = async (plan) => {
    try {
      const res = await JwtService.put(
        `/api/v1/businessPartner/activateBusinessPartnerUsers/${token}`,
        { plan },
        { 'journey-type': 'Register' }
      );
      if (res.codes === 200) {
        toast.success(res.message);
        localStorage.removeItem('plan');
      } else if (res.codes === 400) {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('An error occurred');
    }
  };
  const handleDone = () => {
    history.push('/login');
  };
  return (
    <BoxWrapper>
      <FormLogo />
      <FormCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={'column'} justifyContent={'center'}>
              <Box
                component={'img'}
                src={RSuccessIcon}
                sx={{
                  height: 180,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2} textAlign={'center'}>
              <Typography variant="Body_semibold_28" color="text.primary">
                Registration Successful!
              </Typography>
              <Typography variant="Body_light_14" fontWeight={400} gutterBottom>
                Thanks for verifying your email address. Now taking you to e-doc
                online...
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Button variant="contained" fullWidth onClick={handleDone}>
              Done
            </Button>
          </Grid>
        </Grid>
      </FormCard>
    </BoxWrapper>
  );
};

export default VerifyUser;
