import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, colors } from '@mui/material';

const SessionExpiredPopup = ({ open, onClose }) => {
  const handleLoginAgain = () => {
    localStorage.removeItem('authUser');
    window.location.href = '/login';
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session has expired. Please log in again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLoginAgain} style={{ backgroundColor: '#FC5719', color: '#fff' }} variant="contained">
          Login Again
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpiredPopup;